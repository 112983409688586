"use client";
import { ErrorPage } from "@/components/common/error/error-page";
import { generatedResources } from "@/app/i18n/src";

export default function GlobalError({
  error,
}: // reset,
{
  error: Error & { digest?: string };
  // reset: () => void;
}) {
  const resources = generatedResources["fr-FR"];

  const messages = {
    info: resources.common.error.info(),
    tryANewSearch: resources.common.error.tryAgain(),
  };
  //calling reset() from the global-error causing another error
  // Known issue: https://github.com/vercel/next.js/issues/55462#issuecomment-1896098700
  // hence we call a function to refresh the page
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <html lang="en" className="h-full">
      <body className="h-full">
        <div className="h-full">
          <div className="mx-auto h-full max-w-[1280px]">
            <ErrorPage
              messages={messages}
              error={error}
              reset={handleRefresh}
            />
          </div>
        </div>
      </body>
    </html>
  );
}
